<template>
    <section class="p-4 bg-[#eaedf2]">
        <div class="component-box">
            <div v-loading="fetching_ads_accounts" class="flex flex-wrap gap-2">
                <label-outside-input-select
                    :label="$t('common.advertiser_account')"
                >
                    <el-select
                        :value="selected_ads_account"
                        value-key="advertiser_id"
                        size="small"
                        filterable
                        :placeholder="
                            $t('input.placeholder.select_ads_account')
                        "
                        class="w-[260px]"
                        @change="handleChangeAdsAcc"
                    >
                        <el-option-group
                            :label="$t('common.advertiser_account')"
                        >
                            <el-option
                                v-for="item in ads_accounts"
                                :key="item.advertiser_id"
                                :label="item.name"
                                :value="item"
                            >
                                <div class="relative">
                                    <div
                                        v-if="item.belong_to_atosa"
                                        class="-right-4 bg-brand-atosa--light util-flex-center absolute bottom-0 h-5 px-1 text-white"
                                    >
                                        <span class="text-xxxs uppercase">
                                            atosa
                                        </span>
                                    </div>
                                    <div class="flex flex-col py-1">
                                        <span
                                            class="text-xs font-semibold leading-5"
                                        >
                                            {{ item.name }}
                                        </span>
                                        <div
                                            class="flex items-center space-x-1 leading-3"
                                        >
                                            <i class="el-icon-money"></i>

                                            <span class="text-xs">
                                                {{
                                                    p__renderMoneyByCurrency(
                                                        item.balance,
                                                        item.currency
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <span class="component-text-desc">
                                            #{{ item.advertiser_id }}
                                        </span>
                                    </div>
                                </div>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </label-outside-input-select>

                <label-outside-input-select :label="$t('common.balance')">
                    <el-input
                        size="small"
                        :value="`${p__formatCurrency(
                            current_balance
                        )} ${currency}`"
                        class="w-[140px]"
                    ></el-input>
                </label-outside-input-select>

                <div v-if="show_default">
                    <el-button size="small" @click="handleSetDefaultAdsAccount">
                        {{ $t('common.default') }}
                    </el-button>
                </div>

                <el-button
                    v-if="belong_to_atosa"
                    size="small"
                    type="success"
                    plain
                    icon="el-icon-money"
                    @click="
                        $router.push({
                            path: `${p__group_route.ads_manager}/${
                                p__route.payment
                            }${
                                advertiser_id
                                    ? `?advertiser_id=${advertiser_id}`
                                    : ''
                            }`
                        })
                    "
                >
                    {{ $t('page.payment.add_balance') }}
                </el-button>

                <div class="!ml-auto"></div>

                <filter-wrapper
                    :filtering="filtering"
                    :handle_change_filtering="handleChangeFiltering"
                ></filter-wrapper>

                <div class="relative">
                    <el-input
                        v-model="keyword"
                        class="w-[240px]"
                        size="small"
                        clearable
                        :placeholder="
                            $t('input.placeholder.search_by_name_or_id')
                        "
                        @focus="visible_search = true"
                        @blur="visible_search = false"
                    >
                        <i
                            slot="prefix"
                            class="el-input__icon el-icon-search"
                        ></i>
                    </el-input>

                    <el-popover
                        v-model="visible_search"
                        placement="bottom-start"
                        width="240"
                        trigger="manual"
                    >
                        <ul class="py-2">
                            <li
                                v-for="item in type_keywords"
                                :key="item.value"
                                class="hover:bg-bg py-2 pl-4 cursor-pointer"
                                @click="handleSelectTypeKeyword(item.value)"
                            >
                                <span class="text-xs">{{ item.label }}</span>
                            </li>
                        </ul>
                    </el-popover>
                </div>

                <FilterDatePicker
                    :ranger_date.sync="ranger_date"
                    :switch_compare.sync="switch_compare"
                    :compare_ranger_date.sync="compare_ranger_date"
                ></FilterDatePicker>
            </div>

            <div
                v-if="filtering.length || name_filtering"
                class="flex flex-wrap justify-end gap-1 mt-2"
            >
                <el-button
                    class="mr-2"
                    size="mini"
                    type="danger"
                    plain
                    @click="handleClearAllFilter()"
                    >{{ $t('button.clear_all') }}
                </el-button>
                <el-tag
                    v-if="name_filtering"
                    size="medium"
                    type="info"
                    effect="plain"
                    closable
                    @close="handleRemoveNameFiltering()"
                >
                    {{ $t('common.name') }} : {{ name_filtering }}
                </el-tag>
                <el-tag
                    v-for="(item, index) in filtering"
                    :key="index"
                    size="medium"
                    type="info"
                    effect="plain"
                    closable
                    @close="handleRemoveFilter(index)"
                >
                    {{ renderTitleFilterTag(item) }}
                </el-tag>
            </div>
        </div>

        <ChartWrapper
            class="mt-4"
            :switch_compare="switch_compare"
            :tiktok_account_id="tiktok_account_id"
            :ranger_date="ranger_date"
            :compare_ranger_date="compare_ranger_date"
            :filtering="filtering"
            :currency="currency"
            :advertiser_id="advertiser_id"
        ></ChartWrapper>

        <el-tabs
            ref="el_tab"
            v-model="active_pane"
            type="border-card"
            class="el-tabs-primary mt-4"
        >
            <el-tab-pane name="1">
                <template slot="label">
                    <div class="flex items-center space-x-2">
                        <p>{{ $t('common.campaign') }}</p>

                        <el-tag
                            v-if="multiple_selection_campaign.length"
                            size="small"
                            closable
                            type="info"
                            @close="multiple_selection_campaign = []"
                        >
                            {{ multiple_selection_campaign.length }}
                            <span class="lowercase">
                                {{ $t('common.selected') }}
                            </span>
                        </el-tag>
                    </div>
                </template>
                <CampaignPane
                    :ads_accounts="ads_accounts"
                    :switch_compare="switch_compare"
                    :tiktok_business_user_name="tiktok_business_user_name"
                    :ads_account_name="ads_account_name"
                    :belong_to_atosa="belong_to_atosa"
                    :multiple_selection.sync="multiple_selection_campaign"
                    :advertiser_id="advertiser_id"
                    :current_package="current_package"
                    :currency="currency"
                    :min_budget_campaign="min_budget_campaign"
                    :compare_ranger_date="compare_ranger_date"
                    :ranger_date="ranger_date"
                    :name_filtering="name_filtering"
                    :filtering="filtering"
                    :has_auto_ads="has_auto_ads"
                    :has_manager_ads="has_manager_ads"
                    :has_report_ads="has_report_ads"
                    :type="type"
                    :handle_select_row="handleSelectRow"
                    :tiktok_account_id="tiktok_account_id"
                ></CampaignPane>
            </el-tab-pane>
            <el-tab-pane name="2">
                <template slot="label">
                    <div class="flex items-center space-x-2">
                        <p>
                            {{ $t('common.adgroup') }}
                            <span
                                v-if="
                                    multiple_selection_campaign.length &&
                                    !multiple_selection_adgroup.length
                                "
                            >
                                {{
                                    $t('page.campaign.in_xxx_campaigns', {
                                        xxx: multiple_selection_campaign.length
                                    })
                                }}
                            </span>
                        </p>

                        <el-tag
                            v-if="multiple_selection_adgroup.length"
                            size="small"
                            closable
                            type="info"
                            @close="multiple_selection_adgroup = []"
                        >
                            {{ multiple_selection_adgroup.length }}
                            <span class="lowercase">
                                {{ $t('common.selected') }}
                            </span>
                        </el-tag>
                    </div>
                </template>

                <AdGroupPane
                    :switch_compare="switch_compare"
                    :multiple_selection.sync="multiple_selection_adgroup"
                    :multiple_selection_campaign="multiple_selection_campaign"
                    :current_package="current_package"
                    :compare_ranger_date="compare_ranger_date"
                    :ranger_date="ranger_date"
                    :advertiser_id="advertiser_id"
                    :belong_to_atosa="belong_to_atosa"
                    :min_budget_adgroup="min_budget_adgroup"
                    :name_filtering="name_filtering"
                    :filtering="filtering"
                    :has_auto_ads="has_auto_ads"
                    :has_manager_ads="has_manager_ads"
                    :has_report_ads="has_report_ads"
                    :type="type"
                    :handle_select_row="handleSelectRow"
                    :tiktok_account_id="tiktok_account_id"
                    :current_balance="current_balance"
                    :atosa_bc_list="atosa_bc_list"
                ></AdGroupPane>
            </el-tab-pane>
            <el-tab-pane name="3">
                <template slot="label">
                    <div class="flex items-center space-x-2">
                        <p>
                            {{ $t('common.ad') }}
                            <span
                                v-if="
                                    multiple_selection_adgroup.length &&
                                    !multiple_selection_ad.length
                                "
                            >
                                {{
                                    $t('page.campaign.in_xxx_adgroups', {
                                        xxx: multiple_selection_adgroup.length
                                    })
                                }}
                            </span>
                        </p>

                        <el-tag
                            v-if="multiple_selection_ad.length"
                            size="small"
                            closable
                            type="info"
                            @close="multiple_selection_ad = []"
                        >
                            {{ multiple_selection_ad.length }}
                            <span class="lowercase">
                                {{ $t('common.selected') }}
                            </span>
                        </el-tag>
                    </div>
                </template>
                <AdPane
                    :switch_compare="switch_compare"
                    :multiple_selection.sync="multiple_selection_ad"
                    :multiple_selection_adgroup="multiple_selection_adgroup"
                    :multiple_selection_campaign="multiple_selection_campaign"
                    :current_package="current_package"
                    :bc_id="bc_id"
                    :belong_to_atosa="belong_to_atosa"
                    :compare_ranger_date="compare_ranger_date"
                    :ranger_date="ranger_date"
                    :advertiser_id="advertiser_id"
                    :name_filtering="name_filtering"
                    :filtering="filtering"
                    :has_auto_ads="has_auto_ads"
                    :has_manager_ads="has_manager_ads"
                    :has_report_ads="has_report_ads"
                    :type="type"
                    :handle_select_row="handleSelectRow"
                    :tiktok_account_id="tiktok_account_id"
                ></AdPane>
            </el-tab-pane>
        </el-tabs>
    </section>
</template>

<script>
import CampaignPane from './campaign-pane'
import AdGroupPane from './ad-group-pane'
import AdPane from './ad-pane'
import FilterWrapper from './filter'
import FilterDatePicker from './filter-date-picker'
import moment from 'moment'
import ChartWrapper from './chart-wrapper'

export default {
    components: {
        CampaignPane,
        AdGroupPane,
        AdPane,
        ChartWrapper,
        FilterDatePicker,
        FilterWrapper
    },

    props: [
        'ads_accounts',
        'atosa_bc_list',
        'fetching_ads_accounts',
        'selected_tiktok_business',
        'handleChangeTitle'
    ],

    data() {
        return {
            show_default: false,
            switch_compare: false,
            selected_ads_account: null,
            min_budget_campaign: 0,
            min_budget_adgroup: 0,
            name_filtering: '',
            multiple_selection_campaign: [],
            multiple_selection_adgroup: [],
            multiple_selection_ad: [],
            active_pane: '1',
            keyword: '',
            visible_search: false,
            filtering: [],
            ranger_date: this.p__getRangerDateFromThen(14),
            compare_ranger_date: [
                moment().subtract(15, 'd').format('YYYY-MM-DD'),
                moment().subtract(8, 'd').format('YYYY-MM-DD')
            ],
            type_keywords: [
                {
                    label: this.$t('common.name'),
                    value: 'name_filtering'
                },
                {
                    label: this.$t('common.xxx_id', {
                        xxx: this.$t(`common.campaign`)
                    }),
                    value: 'campaign_ids'
                },
                {
                    label: this.$t('common.xxx_id', {
                        xxx: this.$t(`common.adgroup`)
                    }),
                    value: 'adgroup_ids'
                },
                {
                    label: this.$t('common.xxx_id', {
                        xxx: this.$t(`common.ad`)
                    }),
                    value: 'ad_ids'
                }
            ]
        }
    },

    computed: {
        current_balance() {
            if (this.selected_ads_account) {
                return this.selected_ads_account.balance
            }

            return 0
        },

        tiktok_account_id() {
            if (this.selected_tiktok_business) {
                return this.selected_tiktok_business.id
            }

            return null
        },

        currency() {
            if (this.selected_ads_account) {
                return this.selected_ads_account.currency
            }

            return this.p__default_currency_app
        },

        advertiser_id() {
            if (this.selected_ads_account) {
                return this.selected_ads_account.advertiser_id
            }

            return ''
        },

        ads_account_name() {
            if (this.selected_ads_account) {
                return this.selected_ads_account.name
            }

            return ''
        },

        tiktok_business_user_name() {
            if (this.selected_tiktok_business) {
                return this.selected_tiktok_business.user_name
            }

            return ''
        },

        belong_to_atosa() {
            if (this.selected_ads_account) {
                return this.selected_ads_account.belong_to_atosa
            }

            return ''
        },

        bc_id() {
            if (this.selected_ads_account) {
                return this.selected_ads_account.bc_id
            }

            return ''
        },

        type() {
            if (this.active_pane === '1') {
                return 'campaign_id'
            } else if (this.active_pane === '2') {
                return 'adgroup_id'
            } else if (this.active_pane === '3') {
                return 'ad_id'
            }
            return ''
        },

        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        },

        user_info() {
            return this.$store.getters.user.s__user_info
        },

        has_auto_ads() {
            if (this.user_info?.user?.is_manager) {
                return true
            }

            if (this.user_info?.advertiser_list && this.advertiser_id) {
                const temp = this.user_info?.advertiser_list.find(
                    (item) => item.advertiser_id === this.advertiser_id
                )

                if (temp) {
                    return temp.permissions.some((item) => item === 'AUTO_ADS')
                }
            }

            return false
        },

        has_manager_ads() {
            if (this.user_info?.user?.is_manager) {
                return true
            }

            if (this.user_info?.advertiser_list && this.advertiser_id) {
                const temp = this.user_info?.advertiser_list.find(
                    (item) => item.advertiser_id === this.advertiser_id
                )

                if (temp) {
                    return temp.permissions.some(
                        (item) => item === 'MANAGER_ADS'
                    )
                }
            }

            return false
        },

        has_report_ads() {
            if (this.user_info?.user?.is_manager) {
                return true
            }

            if (this.user_info?.advertiser_list && this.advertiser_id) {
                const temp = this.user_info?.advertiser_list.find(
                    (item) => item.advertiser_id === this.advertiser_id
                )

                if (temp) {
                    return temp.permissions.some(
                        (item) => item === 'REPORT_ADS'
                    )
                }
            }

            return false
        }
    },

    watch: {
        ads_accounts() {
            this.setAdsAccount()
        },

        tiktok_account_id() {
            this.setAdsAccount()
        },

        currency() {
            this.min_budget_adgroup = this.p__findMinimumDailyBudgetAdgroup(
                this.currency
            )

            this.min_budget_campaign = this.p__findMinimumDailyBudgetCampaign(
                this.currency
            )
        },

        selected_ads_account() {
            this.active_pane = '1'

            if (this.selected_ads_account) {
                this.handleChangeTitle(this.selected_ads_account.name)

                if (this.tiktok_account_id) {
                    const temp_id = localStorage.getItem(
                        `id_ads_account_${this.tiktok_account_id}`
                    )

                    this.show_default =
                        temp_id !== this.selected_ads_account.advertiser_id
                }
            }
        },

        multiple_selection_campaign() {
            this.multiple_selection_adgroup = []
        },

        multiple_selection_adgroup() {
            this.multiple_selection_ad = []
        },

        switch_compare() {
            if (this.switch_compare) {
                const start_date = moment(this.ranger_date[0])
                const end_date = moment(this.ranger_date[1])

                const days_diff = end_date.diff(start_date, 'days')

                const second_day = start_date.subtract(1, 'days')
                const first_day = moment(second_day).subtract(days_diff, 'days')

                this.compare_ranger_date = [
                    first_day.format('YYYY-MM-DD'),
                    second_day.format('YYYY-MM-DD')
                ]
            }
        }
    },

    mounted() {
        this.min_budget_adgroup = this.p__findMinimumDailyBudgetAdgroup(
            this.currency
        )

        this.min_budget_campaign = this.p__findMinimumDailyBudgetCampaign(
            this.currency
        )

        const { st, et, filtering, name_filtering, advertiser_id } =
            this.$route.query
        if (st && et && moment(st).isValid() && moment(et).isValid()) {
            this.ranger_date = [st, et]
        }

        if (filtering) {
            this.filtering = JSON.parse(filtering)
        }

        if (name_filtering) {
            this.name_filtering = JSON.parse(name_filtering)
        }
    },

    methods: {
        setAdsAccount() {
            if (this.ads_accounts.length === 0) {
                return
            }

            if (this.$route.query.advertiser_id) {
                const temp = this.ads_accounts.find(
                    (item) =>
                        item.advertiser_id === this.$route.query.advertiser_id
                )

                if (temp) {
                    this.selected_ads_account = temp

                    return
                }
            }

            const temp_id = localStorage.getItem(
                `id_ads_account_${this.tiktok_account_id}`
            )
            if (temp_id) {
                const temp = this.ads_accounts.find(
                    (item) => item.advertiser_id === temp_id
                )

                if (temp) {
                    this.selected_ads_account = temp

                    return
                }
            }

            const atosa_ads_accounts = this.ads_accounts.filter(
                (item) => item.belong_to_atosa
            )

            if (atosa_ads_accounts.length > 0) {
                const max_balance = Math.max(
                    ...atosa_ads_accounts.map((obj) => obj.balance)
                )
                const max_balance_ads_account = atosa_ads_accounts.find(
                    (obj) => obj.balance === max_balance
                )

                this.selected_ads_account = max_balance_ads_account
                return
            }

            this.selected_ads_account = this.ads_accounts[0]
        },

        handleSetDefaultAdsAccount() {
            if (this.tiktok_account_id && this.advertiser_id) {
                localStorage.setItem(
                    `id_ads_account_${this.tiktok_account_id}`,
                    this.advertiser_id
                )

                this.show_default = false
            }
        },

        handleChangeAdsAcc(value) {
            this.multiple_selection_campaign = []
            this.filtering = []

            this.$router.push({
                path: this.$route.fullPath,
                query: { filtering: JSON.stringify([]) }
            })

            this.selected_ads_account = value
        },

        handleSelectTypeKeyword(type) {
            if (!this.keyword) {
                return
            }

            this.handleChangeFiltering(type, this.keyword.trim())

            this.keyword = ''
        },

        handleSelectRow(id, change_filtering = false) {
            let indexPane = 2
            let name = 'adgroup_ids'
            if (this.active_pane === '1') {
                indexPane = 2
                name = 'campaign_ids'
            } else if (this.active_pane === '2') {
                indexPane = 3
                name = 'adgroup_ids'
            } else if (this.active_pane === '3') {
                indexPane = 2
                name = 'adgroup_ids'
            }

            this.handleChangeActivePane(indexPane)
            if (change_filtering) {
                this.handleChangeFiltering(name, id)
            }
        },

        handleChangeFiltering(name, value) {
            if (!value || !name) {
                return
            }

            if (name === 'name_filtering') {
                this.name_filtering = value

                this.$router.push({
                    path: this.$route.fullPath,
                    query: { name_filtering: JSON.stringify(value) }
                })

                return
            }

            let indexFilterById = this.filtering.findIndex(
                (item) => name === item.field_name
            )

            if (indexFilterById > -1) {
                this.filtering.splice(indexFilterById, 1)
            }

            indexFilterById = this.filtering.findIndex((item) =>
                ['campaign_ids', 'adgroup_ids', 'ad_ids'].some(
                    (name) => name === item.field_name
                )
            )

            if (indexFilterById > -1) {
                this.filtering.splice(indexFilterById, 1)
            }

            const temp_filter_value = Array.isArray(value) ? value : [value]

            if (temp_filter_value.length > 0) {
                const filter = {
                    filter_value: JSON.stringify(temp_filter_value),
                    field_name: name,
                    filter_type: 'IN'
                }
                this.filtering.push(filter)
            }
            this.$router.push({
                path: this.$route.fullPath,
                query: { filtering: JSON.stringify(this.filtering) }
            })
        },

        handleChangeActivePane(index) {
            this.active_pane = `${index}`
        },

        handleClearAllFilter() {
            this.keyword = ''
            this.filtering = []
            this.name_filtering = ''

            this.$router.replace({})
        },

        handleRemoveNameFiltering() {
            this.keyword = ''
            this.name_filtering = ''

            this.$router.replace({})

            this.$router.push({
                path: this.$route.fullPath,
                query: {
                    filtering: JSON.stringify(this.filtering),
                    name_filtering: this.name_filtering
                }
            })
        },

        handleRemoveFilter(index) {
            this.filtering.splice(index, 1)
            this.keyword = ''

            this.$router.replace({})

            this.$router.push({
                path: this.$route.fullPath,
                query: {
                    filtering: JSON.stringify(this.filtering),
                    name_filtering: this.name_filtering
                }
            })
        },

        renderTitleFilterTag(item) {
            if (item.field_name === 'campaign_ids') {
                return `${this.$t('common.campaign')}: ${
                    JSON.parse(item.filter_value)[0]
                }`
            }
            if (item.field_name === 'adgroup_ids') {
                return `${this.$t('common.adgroup')}: ${
                    JSON.parse(item.filter_value)[0]
                }`
            }
            if (item.field_name === 'ad_ids') {
                return `${this.$t('common.ad')}: ${
                    JSON.parse(item.filter_value)[0]
                }`
            }

            const length_filter = JSON.parse(item.filter_value).length

            if (item.field_name === 'campaign_status') {
                return `${this.$t('common.xxx_status', {
                    xxx: this.$t('common.campaign')
                })}: ${length_filter}`
            }
            if (item.field_name === 'adgroup_status') {
                return `${this.$t('common.xxx_status', {
                    xxx: this.$t('common.adgroup')
                })}: ${length_filter}`
            }
            if (item.field_name === 'ad_status') {
                return `${this.$t('common.xxx_status', {
                    xxx: this.$t('common.ad')
                })}: ${length_filter}`
            }
            if (item.field_name === 'objective_type') {
                return `${this.$t('common.objective_type')}: ${length_filter}`
            }
            if (item.field_name === 'optimization_goal') {
                return `${this.$t(
                    'page.campaign.optimization_goal'
                )}: ${length_filter}`
            }
        }
    }
}
</script>
