<template>
    <custom-drawer
        v-loading.fullscreen.lock="m__loading"
        size="1000px"
        :wrapper_closable="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ this.$t('page.campaign.adgroup_pane.edit_adgroup') }}
                    </p>
                    <p v-if="objective_type" class="component-text-desc">
                        {{ $t('common.objective_type') }} :
                        {{ p__formatObjectiveType(objective_type) }}
                    </p>
                </div>
                <!-- <el-button
                    type="primary"
                    size="small"
                    @click="handleNextStep"
                >
                    {{ $t('button.next') }}
                    <i class="el-icon-right"></i>
                </el-button> -->
                <el-button
                    type="primary"
                    size="small"
                    @click="handleCopyAdgroups"
                >
                    {{ $t('common.copy') }}
                    <i class="el-icon-copy-document"></i>
                </el-button>
            </div>
        </template>

        <div
            v-if="list_campaigns.length && selected_campaign"
            class="fixed top-[64px] right-[1012px]"
        >
            <div class="component-box w-[280px]">
                <p class="font-semibold text-sm">
                    {{ this.$t('page.campaign.target_campaigns') }}
                </p>

                <ul class="mt-2">
                    <li
                        v-for="item in list_campaigns"
                        :key="item.campaign_id"
                        class="text-sm mt-1 cursor-pointer rounded-lg py-2 px-3"
                        :class="[
                            selected_campaign.campaign_id === item.campaign_id
                                ? 'bg-bg'
                                : 'hover:bg-bg'
                        ]"
                        @click="handleSelectCampaign(item)"
                    >
                        <el-tooltip
                            effect="dark"
                            :content="item.campaign_name"
                            placement="left"
                        >
                            <p class="truncate w-[200px]">
                                {{ item.campaign_name }}
                            </p>
                        </el-tooltip>
                    </li>
                </ul>
            </div>
        </div>

        <div class="flex h-full">
            <div class="w-[68px] bg-white border-r-[1px] border-border">
                <div
                    v-if="
                        selected_campaign && selected_campaign.adgroups.length
                    "
                    class="flex flex-col h-full overflow-auto"
                >
                    <ul class="pt-4">
                        <li
                            v-for="(item, index) in selected_campaign.adgroups"
                            :key="index"
                            class="pb-3 util-flex-center"
                            @click="selected_adgroup = item"
                        >
                            <div
                                class="w-10 h-10 util-flex-center rounded-lg"
                                :class="[
                                    selected_adgroup &&
                                    selected_adgroup.id === item.id
                                        ? 'text-white bg-brand-atosa--light'
                                        : 'bg-bg cursor-pointer'
                                ]"
                            >
                                <p class="text-sm">
                                    {{ index + 1 }}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div
                v-if="selected_adgroup"
                class="flex-1 h-full pt-4 flex space-x-4 px-4 overflow-auto"
            >
                <div class="flex space-x-4 w-full h-fit">
                    <div class="w-2/3">
                        <el-alert
                            v-if="list_errors.length > 0"
                            :title="$t('common.warning')"
                            type="warning"
                            :closable="false"
                            class="mb-4"
                        >
                            <p
                                v-for="(item, index) in list_errors"
                                :key="index"
                            >
                                {{ item }}
                            </p>
                        </el-alert>

                        <EditAdGroupForm
                            :selected_item.sync="selected_adgroup"
                            :currency="currency"
                            :timezone="timezone"
                            :tiktok_account_id="tiktok_account_id"
                            :belong_to_atosa="belong_to_atosa"
                            :advertiser_id="advertiser_id"
                            :objective_type="objective_type"
                            :min_budget_adgroup="min_budget_adgroup"
                            type_form="copy"
                            class="w-full"
                        ></EditAdGroupForm>
                    </div>

                    <div
                        class="flex-1 flex flex-col space-y-4 sticky top-0 left-0 overflow-auto"
                        :style="{ height: `${height_select_box}px` }"
                    >
                        <div class="component-box">
                            <PreviewAdgroup
                                :selected_item="selected_adgroup"
                                :currency="currency"
                            ></PreviewAdgroup>
                        </div>

                        <AvailableAudienceAdgroup
                            class="w-full"
                            :objective_type="objective_type"
                            :selected_item="selected_adgroup"
                            :advertiser_id="advertiser_id"
                            :tiktok_account_id="tiktok_account_id"
                        ></AvailableAudienceAdgroup>

                        <CustomTargetAdgroupBox
                            class="w-full"
                            :tiktok_account_id="tiktok_account_id"
                            :objective_type="objective_type"
                            :selected_item.sync="selected_adgroup"
                        ></CustomTargetAdgroupBox>
                    </div>
                </div>

                <CustomTargetAdgroupBox
                    v-if="
                        selected_adgroup &&
                        ['PRODUCT_SALES'].some(
                            (item) => item !== objective_type
                        )
                    "
                    class="min-w-[320px] w-[320px]"
                    :tiktok_account_id="tiktok_account_id"
                    :objective_type="objective_type"
                    :selected_item.sync="selected_adgroup"
                ></CustomTargetAdgroupBox>
            </div>
        </div>

        <CreateSuccessDialog
            :visible.sync="show_create_success_dialog"
            :advertiser_id="advertiser_id"
            :current_balance="current_balance"
            :currency="currency"
            @skip="visible_in_line = false"
        ></CreateSuccessDialog>

        <ProcessDialog
            :visible="
                m__loading &&
                infor_loading.index > 0 &&
                infor_loading.amount > 0
            "
            :index="infor_loading.index"
            :amount="infor_loading.amount"
        ></ProcessDialog>
    </custom-drawer>
</template>

<script>
import { getAds } from '@/services/atosa-tiktok-ads/ad'
import { createFullAdgroup } from '@/services/atosa-tiktok-ads/campaign'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { removeEmptyParams } from '@/views/campaign/campaign-wrapper/_utils'
import EditAdgroupFormMixin from '@/components/orgranisms/edit-adgroup-form/_mixin'
import CreateSuccessDialog from './create-success-dialog'
import moment from 'moment'

export default {
    components: {
        CreateSuccessDialog
    },

    mixins: [selectedTiktokBusinessMixin, EditAdgroupFormMixin],

    props: [
        'visible',
        'advertiser_id',
        'currency',
        'timezone',
        'min_budget_adgroup',
        'objective_type',
        'list_campaigns',
        'current_balance',
        'belong_to_atosa'
    ],

    data() {
        return {
            height_select_box: 680,
            show_create_success_dialog: false,
            step: 1,
            selected_campaign: null,
            selected_adgroup: null,
            selected_ad: null,
            list_ads: [],
            list_errors: [],
            audience_size: null,
            infor_loading: {
                index: 0,
                amount: 0
            }
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        tiktok_account_id() {
            return this.m__selected_tiktok_business
                ? this.m__selected_tiktok_business.id
                : null
        }
    },

    watch: {
        visible() {
            if (this.visible && this.list_campaigns.length) {
                this.list_errors = []

                this.handleSelectCampaign(this.list_campaigns[0])

                this.fetchAds()
            }
        }
    },

    mounted() {
        const height = document.getElementById('view_layout')?.clientHeight || 0
        if (height > 0) {
            this.height_select_box = height - 60
        }

        this.p__event_bus.$on(
            'eb_error_adgroup_no_find_identity',
            this.pushError
        )

        this.p__event_bus.$on('eb_error_adgroup_no_find_shop', this.pushError)
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_error_adgroup_no_find_identity',
            this.pushError
        )
        this.p__event_bus.$off('eb_error_adgroup_no_find_shop', this.pushError)
    },

    methods: {
        pushError(err) {
            this.list_errors.push(err)
        },

        handleSelectCampaign(item) {
            this.selected_campaign = item
            this.selected_adgroup = item.adgroups[0]
        },

        async fetchAds() {
            this.m__loading = true

            this.infor_loading.index = 0
            this.infor_loading.amount = 0

            try {
                const response_get_ads = await getAds(
                    this.m__selected_tiktok_business.id,
                    {
                        advertiser_id: this.advertiser_id,
                        filtering: JSON.stringify({
                            adgroup_ids: [this.selected_adgroup.adgroup_id]
                        })
                    }
                )

                const list_ads = response_get_ads.data.list.map((item) => {
                    if (!item.ad_text) {
                        item.ad_text = 'ad'
                    }
                    delete item.brand_safety_postbid_partner
                    removeEmptyParams(item)
                    return item
                })

                if (list_ads.length) {
                    this.list_ads = list_ads
                } else {
                    this.pushError(
                        this.$t(
                            'page.campaign.message_adgroup_doesnt_have_any_ads'
                        )
                    )
                }
            } catch (error) {
                console.error(error)
            }
            this.m__loading = false
        },

        async handleCopyAdgroups() {
            if (!this.list_ads.length) {
                return
            }

            let pass = false
            for (const item_campaign of this.list_campaigns) {
                for (const item_adgroup of item_campaign.adgroups) {
                    pass = this.m__validAdgroup(
                        item_adgroup,
                        this.min_budget_adgroup,
                        this.objective_type
                    )
                    if (!pass) {
                        this.selected_campaign = item_campaign
                        this.selected_adgroup = item_adgroup
                        return
                    }
                }
                if (!pass) {
                    return
                }
            }
            if (!pass) {
                return
            }

            this.m__loading = true

            try {
                let has_success = false

                this.infor_loading.index = 0

                let amount = 0
                for (const campaign of this.list_campaigns) {
                    amount += campaign.adgroups.length
                }
                this.infor_loading.amount = amount

                const list_ads = this.list_ads.map((item) => {
                    if (!item.ad_text) {
                        item.ad_text = 'ad'
                    }
                    if (item.creative_type === 'SHOPPING_SHOP_PAGE') {
                        item.creative_type = 'PSA'
                    }
                    delete item.brand_safety_postbid_partner
                    removeEmptyParams(item)
                    return item
                })

                await Promise.all(
                    this.list_campaigns.map(async (campaign) => {
                        let index = 0

                        for (const adgroup of campaign.adgroups) {
                            index++
                            this.infor_loading.index++

                            const temp_adgroup = {
                                ...JSON.parse(JSON.stringify(adgroup)),
                                config_list: [],
                                creative: {
                                    creatives: list_ads
                                }
                            }

                            if (
                                temp_adgroup.budget_mode === 'BUDGET_MODE_DAY'
                            ) {
                                temp_adgroup.config_list =
                                    temp_adgroup.extension.config_list
                                        .filter(
                                            (item) => item.type !== 'delete'
                                        )
                                        .map((item) => ({
                                            ...item,
                                            time: moment(item.time).unix()
                                        }))
                            }

                            try {
                                removeEmptyParams(temp_adgroup)

                                this.m__changeFormatScheduleTime(temp_adgroup)

                                this.m__changeActionCategories(temp_adgroup)

                                const response_create_adgroup =
                                    await createFullAdgroup(
                                        this.m__selected_tiktok_business.id,
                                        {
                                            ...temp_adgroup,
                                            campaign_id: campaign.campaign_id,
                                            belong_to_atosa:
                                                this.belong_to_atosa
                                        }
                                    )

                                if (response_create_adgroup.code === 0) {
                                    has_success = true
                                }
                            } catch (error) {
                                console.error(error)
                                this.p__showNotify(
                                    'error',
                                    `${campaign.campaign_name} - Ad Group ${index} : ${error.data.message}`
                                )
                            }
                        }
                    })
                )

                if (has_success) {
                    this.p__showNotify(
                        'success',
                        this.$t('message.xxx_success', {
                            xxx: `${this.$t(`common.copy`)} ${this.$t(
                                `common.adgroup`
                            )}`
                        })
                    )

                    setTimeout(() => {
                        this.p__event_bus.$emit('eb_reload_campaign')
                        this.p__event_bus.$emit('eb_reload_adgroup')
                        this.p__event_bus.$emit('eb_reload_ad')
                    }, 1111)

                    if (this.current_balance <= 300_000) {
                        this.show_create_success_dialog = true
                    } else {
                        this.visible_in_line = false
                    }
                }
            } catch (error) {
                console.error(error)
                this.p__showNotify(
                    'error',
                    this.$t('message.xxx_fail', {
                        xxx: `${this.$t(`common.copy`)} ${this.$t(
                            `common.adgroup`
                        )}`
                    })
                )
            }
            this.m__loading = false
        }
    }
}
</script>
