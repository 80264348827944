<template>
    <div class="mt-4">
        <div v-if="m__page_info" class="flex items-center mb-2 space-x-2">
            <p class="text-sm">Nhóm quảng cáo cùng mục tiêu quảng cáo</p>

            <div class="!ml-auto"></div>

            <!-- <el-date-picker
                v-model="ranger_date_inline"
                class="!w-[280px]"
                size="small"
                popper-class="custom-el-date"
                type="daterange"
                unlink-panels
                :start-placeholder="$t('date_picker.placeholder.start')"
                :end-placeholder="$t('date_picker.placeholder.end')"
                :picker-options="m__picker_options"
                :format="$t('common.format_value_date_picker')"
                value-format="yyyy-MM-dd"
                @change="handleChangeDateRange"
            >
            </el-date-picker> -->

            <el-select
                v-model="m__page_info.page_size"
                filterable
                class="w-[120px]"
                size="small"
                @change="
                    m__handleChangePagesize(m__getReportCampaignsByAccount)
                "
            >
                <el-option
                    v-for="i in m__list_pagesize"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value"
                >
                </el-option>
            </el-select>

            <el-input-number
                v-model="m__page_info.page"
                class="w-[120px]"
                size="small"
                :min="1"
                :max="m__page_info.total_page"
                @change="m__getReportCampaignsByAccount"
            ></el-input-number>

            <el-button
                icon="el-icon-refresh"
                size="small"
                @click="m__getReportCampaignsByAccount"
            ></el-button>
        </div>

        <el-table
            :data="m__data_for_pagination"
            :max-height="360"
            @sort-change="m__handleSortChange"
        >
            <el-table-column
                width="300"
                prop="adgroup.adgroup_name"
                :label="$t('common.name')"
            >
                <template #default="scope">
                    <div class="group hover:text-brand-atosa--light">
                        <div class="group cursor-pointer">
                            <p>
                                ID:
                                {{ scope.row.adgroup.adgroup_id }}
                            </p>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row.adgroup.adgroup_name"
                                placement="top"
                            >
                                <p>
                                    {{ scope.row.adgroup.adgroup_name }}
                                </p>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                width="160"
                :show-overflow-tooltip="true"
                prop="adgroup.campaign_id"
                :label="
                    $t('common.xxx_id', {
                        xxx: $t(`common.campaign`)
                    })
                "
            />

            <el-table-column :label="$t('common.budget')" width="100px">
                <template #default="scope">
                    <p class="font-semibold">
                        {{
                            p__renderMoneyByCurrency(
                                scope.row.adgroup.budget,
                                scope.row.currency
                            )
                        }}
                    </p>
                    <p class="component-text-desc">
                        {{ p__formatBudgetMode(scope.row.adgroup.budget_mode) }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                width="120"
                :show-overflow-tooltip="true"
                prop="adgroup.bid_price"
                label="BID"
                align="right"
            />

            <el-table-column :label="$t('common.ad_scheduling')" width="160px">
                <template #default="scope">
                    <p>
                        {{
                            p__formatDate(scope.row.adgroup.schedule_start_time)
                        }}
                    </p>
                    <p>
                        {{ p__formatDate(scope.row.adgroup.schedule_end_time) }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                v-for="(item, index) in m__selected_columns"
                :key="index"
                width="160"
                :show-overflow-tooltip="true"
                :prop="item"
                :label="$t(`page.campaign.column_${item}`)"
                sortable="custom"
                align="right"
            >
                <template v-slot:header>
                    <el-tooltip
                        class="item"
                        :content="$t(`page.campaign.column_${item}`)"
                        effect="dark"
                        placement="top"
                    >
                        <p>
                            {{ $t(`page.campaign.column_${item}`) }}
                        </p>
                    </el-tooltip>
                </template>

                <template #default="scope">
                    <p>
                        {{
                            m__formatterColumn(
                                scope.row[item],
                                item,
                                scope.row.currency
                            )
                        }}
                    </p>
                    <p v-if="scope.row._compare" class="text-desc-text">
                        {{
                            m__formatterColumn(
                                scope.row._compare[item],
                                item,
                                scope.row.currency
                            )
                        }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('common.option')"
                fixed="right"
                width="120"
            >
                <template #default="scope">
                    <el-button
                        size="small"
                        @click="handleChooseAdgroup(scope.row)"
                    >
                        {{ $t('button.choose') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import campaignMixin from '../../../_mixin'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'

export default {
    mixins: [campaignMixin, selectedTiktokBusinessMixin],

    props: ['copy_item', 'tiktok_account_id', 'handleChooseAdgroup'],

    mounted() {
        this.m__type = 'adgroup_id'

        const filter = [
            {
                filter_value: JSON.stringify([this.copy_item.objective_type]),
                field_name: 'objective_type',
                filter_type: 'IN'
            }
        ]
        this.m__filtering = filter
        this.m__getReportCampaignsByAccount()
    }
}
</script>
