<template>
    <el-dialog
        :visible.sync="visible_in_line"
        :append-to-body="true"
        :show-close="false"
        width="520px"
    >
        <template slot="title">
            <p class="text-left truncate w-4/5 text-base font-semibold">
                {{ `${$t('common.copy_adgroup')}` }}
            </p>
        </template>
        <div>
            <p class="component-label">
                {{ $t('page.campaign.target_campaigns') }}
            </p>
            <el-select
                v-model="list_campaigns"
                v-el-select-loadmore="loadMore"
                value-key="campaign_id"
                size="small"
                multiple
                :placeholder="$t('input.placeholder.please_select')"
                filterable
                class="w-full"
            >
                <el-option
                    v-for="item in m__data_for_pagination"
                    :key="item.campaign_id"
                    :label="item.campaign_name"
                    :value="item"
                >
                </el-option>
            </el-select>

            <!-- <el-input
                :value="
                    active_item_for_copy
                        ? active_item_for_copy.campaign_name
                        : ''
                "
                class="flex-1"
                size="small"
                disabled
            ></el-input> -->

            <p class="component-label mt-6">
                {{ $t('page.campaign.adgroup_pane.number_copies') }}
            </p>
            <el-input-number
                v-model="number_copy"
                class="w-[120px]"
                size="small"
                :min="1"
                :max="p__number_max_copy_adgroup"
            ></el-input-number>
        </div>

        <template slot="footer">
            <div class="flex items-center justify-end">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-check"
                    :disabled="!list_campaigns.length"
                    @click="handleConfirmOpenCopyDrawer"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { getCampaigns } from '@/services/atosa-tiktok-ads/campaign'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import moment from 'moment'

export default {
    mixins: [paginationDataMixin],

    props: [
        'visible',
        'active_item_for_copy',
        'objective_type',
        'tiktok_account_id',
        'advertiser_id',
        'handle_confirm_copy'
    ],

    data() {
        return {
            number_copy: 1,
            list_campaigns: []
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        objective_type_in_line: {
            get() {
                return this.objective_type
            },
            set(value) {
                this.$emit('update:objective_type', value)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line && this.active_item_for_copy) {
                this.m__data_for_pagination = []
                this.m__page_info.page = 1

                this.list_campaigns = []

                this.number_copy = 1

                this.fetchDetailCampaign()
            }
        }
    },

    methods: {
        async fetchDetailCampaign() {
            const response = await getCampaigns(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                filtering: JSON.stringify({
                    campaign_ids: [this.active_item_for_copy.campaign_id]
                })
            })

            if (
                response.code === 0 &&
                response.data.list.length &&
                response.data.list[0]
            ) {
                const first_campaign = response.data.list[0]

                this.objective_type_in_line = first_campaign.objective_type

                this.m__data_for_pagination.push(first_campaign)

                this.list_campaigns.push(first_campaign)

                this.fetchCampaignsByType(first_campaign.objective_type)
            }
        },

        async fetchCampaignsByType(objective_type) {
            this.m__loading = true

            const response = await getCampaigns(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                filtering: JSON.stringify({
                    objective_type
                }),
                page: this.m__page_info.page,
                page_size: this.m__page_info.page_size
            })

            const first_campaign = this.m__data_for_pagination[0]

            this.m__data_for_pagination = [
                ...this.m__data_for_pagination,
                ...response.data.list
            ].filter((item) => item.campaign_id !== first_campaign.campaign_id)

            this.m__data_for_pagination = [
                first_campaign,
                ...this.m__data_for_pagination
            ]

            this.m__page_info = response.data.page_info

            this.m__loading = false
        },

        loadMore() {
            if (this.m__loading) {
                return
            }

            if (this.m__page_info.page < this.m__page_info.total_page) {
                this.m__page_info.page++
                this.fetchCampaignsByType(this.objective_type_in_line)
            }
        },

        handleConfirmOpenCopyDrawer() {
            if (!this.list_campaigns.length) {
                return
            }

            this.visible_in_line = false

            const list_campaigns = this.list_campaigns.map((x) => {
                let schedule_start_time =
                    this.active_item_for_copy.schedule_start_time
                let schedule_end_time =
                    this.active_item_for_copy.schedule_end_time
                const start_time = moment(schedule_start_time)
                const end_time = moment(schedule_end_time)
                const now = moment()

                if (start_time <= now || end_time <= now) {
                    if (this.objective_type === 'PRODUCT_SALES') {
                        schedule_start_time = now
                            .add(10, 'minutes')
                            .format(this.p__format_schedule_tiktok_time)
                        schedule_end_time = now
                            .add(2, 'hours')
                            .format(this.p__format_schedule_tiktok_time)
                    } else {
                        schedule_start_time = now
                            .add(1, 'days')
                            .format(this.p__format_schedule_tiktok_time)
                        schedule_end_time = now
                            .add(31, 'days')
                            .format(this.p__format_schedule_tiktok_time)
                    }
                }

                const list_copy = Array.from(
                    { length: this.number_copy },
                    (_, i) =>
                        JSON.parse(
                            JSON.stringify({
                                ...this.active_item_for_copy,
                                id: `${
                                    this.active_item_for_copy.adgroup_id
                                }_${this.p__renderId()}`,
                                adgroup_name: `${
                                    this.active_item_for_copy.adgroup_name
                                }_Copy_${i + 1}`,
                                extension:
                                    this.p__default_state_infor_adgroup
                                        .extension,
                                schedule_start_time,
                                schedule_end_time
                            })
                        )
                )
                return {
                    ...x,
                    adgroups: list_copy
                }
            })

            this.handle_confirm_copy(list_campaigns)
        }
    }
}
</script>
